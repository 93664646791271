"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.string.includes.js");
var _promise = _interopRequireDefault(require("core-js-pure/features/promise"));
var _state = _interopRequireDefault(require("../state"));
var _store = require("../store");
var _utilities = require("../helpers/utilities");
var _render = require("../helpers/render");
var _facebook = require("../helpers/facebook");
var _admiral = _interopRequireDefault(require("../helpers/admiral"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function fly(feathrAccountId) {
  var _this = this;
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!(0, _utilities.isObjectId)(feathrAccountId)) {
    (0, _utilities.log)('error', 'Invalid Feathr Account Id.');
    return false;
  }
  (0, _render.renderPartnerDashboardButtons)();
  if (_store.boomerangsById[feathrAccountId]) {
    (0, _utilities.log)('Prematurely returning from `fly` because `fly` has already been invoked for this pixel and account id.');
    return _store.boomerangsById[feathrAccountId].state.initialized;
  }
  var state = new _state["default"](feathrAccountId, this);
  (0, _utilities.log)("setting account id ".concat(feathrAccountId));
  state.accountId = feathrAccountId;
  state.loaded = true;
  if (options.devMode) {
    state.devMode = true;
  }
  if (options.bypassHash) {
    state.bypassHash = true;
  }
  if (options.integrations !== undefined) {
    state.integrations = options.integrations;
  }
  if (options.trackingMode) {
    state.trackingMode = options.trackingMode;
  }
  if (options.trackClicks !== undefined) {
    state.trackClicks = options.trackClicks;
  }
  if (options.destinationUrl) {
    state.destinationUrl = options.destinationUrl;
  }
  if (options.clickFlavor) {
    state.clickFlavor = options.clickFlavor;
  }
  var boomerang = {
    state: state,
    feathrAccountId: feathrAccountId,
    feathr: this
  };
  _store.boomerangs.push(boomerang);
  _store.boomerangsById[feathrAccountId] = boomerang;
  this.boomerang = boomerang;
  window[this.pixelKey].accountId = feathrAccountId;
  return state.initialize(feathrAccountId)
  // eslint-disable-next-line
  .then(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      cookied = _ref2[0],
      integrations = _ref2[1];
    try {
      (0, _render.renderMatchCode)(options.devMode, _this.pixelKey);
      if (state.trackingMode === 'page') {
        (0, _render.renderPixelCode)(feathrAccountId, options.devMode, _this.pixelKey);
      }
      if ((0, _utilities.isArray)(integrations) && integrations.includes('facebook')) {
        (0, _facebook.renderFacebookPixelCode)(state.accountId, options.devMode, _this.pixelKey);
      }
      if ((0, _utilities.isArray)(integrations) && integrations.includes('admiral')) {
        (0, _admiral["default"])(state.accountId);
      }
    } catch (error) {
      (0, _utilities.log)('error', error);
      return _promise["default"].resolve(false);
    }
  })["catch"](function (reason) {
    (0, _utilities.log)('error', "Initialization failed: ".concat(reason));
  });
}
var _default = exports["default"] = fly;