"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.number.constructor.js");
var _utilities = require("../helpers/utilities");
var _render = require("../helpers/render");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function execute(state, breadcrumbParams, fId) {
  try {
    var params = _objectSpread({}, breadcrumbParams);
    if (fId) {
      params.f_id = fId;
    }
    if (state.ttdId) {
      params.ttd_id = state.ttdId;
    }
    (0, _render.renderBreadcrumb)(params, state);
  } catch (error) {
    (0, _utilities.log)('error', 'Failed to execute `convert`');
  }
}
function getConvertParams(state, segId, amount, currency, category, params) {
  var breadcrumbParams;
  if ((0, _utilities.isObject)(params)) {
    breadcrumbParams = _objectSpread({}, params);
  }
  breadcrumbParams = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (0, _utilities.getWindowParameters)()), (0, _utilities.getDefaultParameters)()), breadcrumbParams), {}, {
    flvr: 'convert',
    fcv: {
      // Make sure we always include amount.
      amount: amount || 0,
      category: category || 'Conversion',
      currency: currency || 'USD'
    },
    a_id: state.accountId,
    seg_id: segId
  });
  return breadcrumbParams;
}
function convert(segId, conversionParams, additionalParams) {
  var state = this.boomerang.state;
  var amount = conversionParams.amount;
  var category = conversionParams.category || 'Conversion';
  var currency = conversionParams.currency || 'USD';
  if (state.accountId === undefined || !(0, _utilities.isObjectId)(state.accountId)) {
    return (0, _utilities.log)('error', 'Cannot `convert` without valid Account Id.');
  }
  if (typeof segId !== 'string') {
    return (0, _utilities.log)('error', 'Conversion sprinkle id must be a string.');
  }
  if (typeof amount !== 'number') {
    return (0, _utilities.log)('error', 'Conversion amount must be a number.');
  } else if (amount < 0) {
    return (0, _utilities.log)('error', 'Conversion amount must be in the range [0, Infinity].');
  }
  var breadcrumbParams = getConvertParams(state, segId, amount, currency, category, additionalParams);
  if ((0, _utilities.isPromise)(state.initialized)) {
    state.initialized.then(function () {
      if ((0, _utilities.isPromise)(state.fId)) {
        state.fId.then(function (fId) {
          execute(state, breadcrumbParams, fId);
        })["catch"](function () {
          execute(state, breadcrumbParams, null);
        });
      } else {
        execute(state, breadcrumbParams, state.fId);
      }
    });
  } else if (state.initialized === true && (0, _utilities.isPromise)(state.fId)) {
    state.fId.then(function (fId) {
      execute(state, breadcrumbParams, fId);
    })["catch"](function () {
      execute(state, breadcrumbParams, null);
    });
  } else if (state.initialized === true) {
    execute(state, breadcrumbParams, state.fId);
  } else {
    (0, _utilities.log)('error', 'Cannot `convert` with uninitialized Boomerang.');
  }
  return true;
}
var _default = exports["default"] = convert;