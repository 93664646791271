"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
var _utilities = require("../helpers/utilities");
var _fetch = require("../helpers/fetch");
var _excluded = ["date_created", "date_last_seen", "date_last_heard_from", "date_last_session_start", "parent", "cookies", "seen_count", "session_count", "conv_count", "custom_data", "last_ip", "integrations", "emails", "address", "geoip", "social_identifiers", "avatar"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function filterBlacklisted(data) {
  var date_created = data.date_created,
    date_last_seen = data.date_last_seen,
    date_last_heard_from = data.date_last_heard_from,
    date_last_session_start = data.date_last_session_start,
    parent = data.parent,
    cookies = data.cookies,
    seen_count = data.seen_count,
    session_count = data.session_count,
    conv_count = data.conv_count,
    custom_data = data.custom_data,
    last_ip = data.last_ip,
    integrations = data.integrations,
    emails = data.emails,
    address = data.address,
    geoip = data.geoip,
    social_identifiers = data.social_identifiers,
    avatar = data.avatar,
    valid = _objectWithoutProperties(data, _excluded);
  return valid;
}
function fetchAnalyticsUpdate(state, fId, data) {
  var crumb = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var filteredData = filterBlacklisted(data);
  var fetchData = {
    f_id: fId,
    data: filteredData
  };
  if (Object.keys(crumb).length) {
    fetchData.crumb = _objectSpread(_objectSpread(_objectSpread({}, (0, _utilities.getDefaultParameters)()), (0, _utilities.getWindowParameters)()), crumb);
  }
  var fetchOptions = {
    body: JSON.stringify(fetchData),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    method: 'POST'
  };
  var endpoint = (0, _utilities.getPoloUrl)("/analytics/update/".concat(state.accountId), state.devMode);
  return (0, _fetch.fetch)(endpoint, fetchOptions);
}
function update(data) {
  var crumb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var state = this.boomerang.state;
  if (!state.accountId) {
    (0, _utilities.log)('error', 'Cannot update before `fly` or without an account id.');
    return false;
  }
  var requestPromise;
  if ((0, _utilities.isPromise)(state.fId)) {
    requestPromise = state.fId.then(function (fId) {
      return fetchAnalyticsUpdate(state, fId, data, crumb);
    });
  } else {
    requestPromise = fetchAnalyticsUpdate(state, state.fId, data, crumb);
  }
  return requestPromise.then(function (response) {
    try {
      return response.json();
    } catch (error) {
      (0, _utilities.log)('error', error);
      return {};
    }
  }).then(function (responseData) {
    (0, _utilities.log)("Update response: ".concat(JSON.stringify(responseData)));
  })["catch"](function (reason) {
    (0, _utilities.log)('error', "Update request failed: ".concat(reason, "."));
  });
}
var _default = exports["default"] = update;