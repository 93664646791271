"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _render = require("../helpers/render");
var _utilities = require("../helpers/utilities");
function integrate(platform, id) {
  var state = this.boomerang.state;
  switch (platform) {
    case 'ttd':
      if (!state.renderedTtdMatch) {
        (0, _render.renderTtdMatch)(id, this.pixelKey);
        state.renderedTtdMatch = true;
      }
      break;
    case 'facebook':
      if (id === null || id === undefined || id === '') {
        (0, _utilities.log)('error', 'No pixel id found.');
        state.facebookPixelIdRejector(id);
      } else {
        state.facebookPixelIdResolver(id);
      }
      state.facebookPixelId = id;
      break;
    default:
      break;
  }
}
var _default = exports["default"] = integrate;