"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _promise = _interopRequireDefault(require("core-js-pure/features/promise"));
var _utilities = require("../helpers/utilities");
var _render = require("../helpers/render");
var _facebook = require("../helpers/facebook");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function execute(state, breadcrumbParams, integrations, fId) {
  try {
    var params = _objectSpread({}, breadcrumbParams);
    if (fId) {
      params.f_id = fId;
    }
    if (state.ttdId) {
      params.ttd_id = state.ttdId;
    }
    (0, _render.renderBreadcrumb)(params, state);
    if (state.trackingMode === 'page' && state.trackClicks) {
      (0, _render.registerLinkClickEvents)(params, state);
    } else if (state.trackingMode === 'ad') {
      (0, _render.registerAdClickEvent)(params, state);
    }
    if ((0, _utilities.isArray)(integrations) && integrations.includes('facebook')) {
      (0, _facebook.facebookSprinkle)(state, state.devMode);
    }
  } catch (error) {
    (0, _utilities.log)('error', 'Failed to execute sprinkle');
  }
}
function getBreadcrumbParams(flavor, state) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var breadcrumbParams;
  if ((0, _utilities.isObject)(flavor)) {
    breadcrumbParams = _objectSpread({}, flavor);
  } else if ((0, _utilities.isObject)(params)) {
    breadcrumbParams = _objectSpread({
      flvr: flavor
    }, params);
  } else {
    breadcrumbParams = {
      flvr: flavor
    };
  }
  breadcrumbParams = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (0, _utilities.getWindowParameters)()), (0, _utilities.getDefaultParameters)()), breadcrumbParams), {}, {
    a_id: state.accountId,
    debug: state.devMode ? 1 : 0,
    bypass_hash: state.bypassHash ? 1 : 0
  });
  return breadcrumbParams;
}
function trackChanges(state, params, fId) {
  try {
    if (!state.oldHref && window.MutationObserver) {
      // we have to set the oldHref on the state so that we have a stable reference
      // eslint-disable-next-line no-param-reassign
      state.oldHref = window.location.href;
      var body = document.querySelector('body');
      var observer = new MutationObserver(function () {
        var sprinkleParams = _objectSpread({}, params);
        if (fId) {
          sprinkleParams.f_id = fId;
        }
        if (state.ttdId) {
          sprinkleParams.ttd_id = state.ttdId;
        }
        try {
          var newHref = window.location.href;
          (0, _render.registerLinkClickEvents)(sprinkleParams, state);
          if (newHref !== state.oldHref) {
            (0, _render.renderBreadcrumb)(sprinkleParams, state);
            // eslint-disable-next-line no-param-reassign
            state.oldHref = newHref;
          }
        } catch (error) {
          (0, _utilities.log)('error', 'Failed to track possible location change.');
        }
      });
      observer.observe(body, {
        childList: true,
        subtree: true
      });
    }
  } catch (error) {
    (0, _utilities.log)('error', 'Failed to setup automatic location change tracking.');
  }
}
function sprinkle(flavor) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!arguments.length) return false;
  if (!flavor) {
    return (0, _utilities.log)('error', 'Attempting to sprinkle breadcrumb without well defined flvr or parameters', {
      flavor: flavor,
      params: params
    });
  }
  var state = this.boomerang.state;
  if (state.accountId === undefined || !(0, _utilities.isObjectId)(state.accountId)) {
    return (0, _utilities.log)('error', 'Cannot `sprinkle` without valid Account Id.');
  }
  var breadcrumbParams = getBreadcrumbParams(flavor, state, params);
  if ((0, _utilities.isPromise)(state.initialized)) {
    state.initialized.then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        integrations = _ref2[1];
      _promise["default"].all([state.fId, state.ttdId]).then(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 1),
          fId = _ref4[0];
        trackChanges(state, breadcrumbParams, fId);
        execute(state, breadcrumbParams, integrations, fId);
      })["catch"](function () {
        trackChanges(state, breadcrumbParams, null);
        execute(state, breadcrumbParams, integrations, null);
      });
    });
  } else if (state.initialized === true) {
    _promise["default"].all([state.fId, state.ttdId]).then(function (_ref5) {
      var _ref6 = _slicedToArray(_ref5, 1),
        fId = _ref6[0];
      trackChanges(state, breadcrumbParams, fId);
      execute(state, breadcrumbParams, state.integrations, fId);
    })["catch"](function () {
      trackChanges(state, breadcrumbParams, null);
      execute(state, breadcrumbParams, state.integrations, null);
    });
  } else {
    (0, _utilities.log)('error', 'Cannot `sprinkle` with uninitialized Boomerang.');
  }
  return true;
}
var _default = exports["default"] = sprinkle;