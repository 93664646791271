"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _utilities = require("./utilities");
function renderAdmiralPixelCode(accountId) {
  var devMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!(0, _utilities.isObjectId)(accountId)) {
    return null;
  }
  (0, _utilities.log)("render admiral pixel for ".concat(accountId));
  var pixel = window.document.createElement('script');
  pixel.setAttribute('async', 'true');
  pixel.type = 'text/javascript';
  pixel.src = (0, _utilities.getPoloUrl)("/accounts/".concat(accountId, "/integrations/admiral/pixel.js"), 1, devMode);
  (0, _utilities.head)().appendChild(pixel);
  return true;
}
var _default = exports["default"] = renderAdmiralPixelCode;