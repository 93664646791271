"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _invoke = _interopRequireDefault(require("./invoke"));
var _utilities = require("./helpers/utilities");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function process(queue) {
  if (!(0, _utilities.isArray)(queue)) {
    (0, _utilities.log)('error', 'provided queue is not an array.');
    return false;
  }
  if (this !== undefined && !this.initialized) {
    this.initialized = true;
  }
  while (queue.length) {
    try {
      (0, _utilities.log)('Processing queue');
      var args = queue.shift();
      _invoke["default"].apply(this, args);
    } catch (error) {
      (0, _utilities.log)('error', error);
    }
  }
  return setTimeout(process, 50, queue);
}
var _default = exports["default"] = process;