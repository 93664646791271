"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.facebookPixel = void 0;
exports.facebookSprinkle = facebookSprinkle;
exports.renderFacebookPixelCode = renderFacebookPixelCode;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _promise = _interopRequireDefault(require("core-js-pure/features/promise"));
var _utilities = require("./utilities");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var fbqResolve;
var fbqReject;
var facebookPixel = exports.facebookPixel = new _promise["default"](function (resolve, reject) {
  fbqResolve = resolve;
  fbqReject = reject;
});
function facebookPixelOnLoad() {
  if (window.fbq) {
    return fbqResolve(window.fbq);
  }
  return fbqReject();
}
function renderFacebookPixelCode(accountId) {
  var devMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var pixelKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'feathr';
  if (!(0, _utilities.isObjectId)(accountId)) {
    return null;
  }
  (0, _utilities.log)("render facebook pixel for ".concat(accountId));
  return (0, _utilities.addOnLoadEventListener)(function () {
    (0, _utilities.log)('render facebook pixel load event callback');
    var pixel = window.document.createElement('script');
    pixel.setAttribute('async', 'true');
    pixel.type = 'text/javascript';
    pixel.onload = facebookPixelOnLoad;
    pixel.src = "".concat((0, _utilities.getPoloUrl)("/accounts/".concat(accountId, "/integrations/facebook/pixel.js"), 1, devMode), "?pk=").concat(pixelKey);
    (0, _utilities.head)().appendChild(pixel);
  });
}
function facebookSprinkle(state) {
  var devMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var trackType = 'trackSingle';
  var eventName = 'ViewContent';
  _promise["default"].all([facebookPixel, state.facebookPixelId]).then(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      fbq = _ref2[0],
      pId = _ref2[1];
    if (!devMode) {
      (0, _utilities.log)("Calling fbq(".concat(trackType, ", ").concat(pId, ", ").concat(eventName, ")"));
      fbq(trackType, pId, eventName);
    }
  })["catch"](function () {
    (0, _utilities.log)('error', 'facebook pixel failed to load');
  });
  return true;
}