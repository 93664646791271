"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _utilities = require("../helpers/utilities");
function match(fId, ttdId) {
  var state = this.boomerang.state;
  if (state.fIdTimeout) {
    clearTimeout(state.fIdTimeout);
  }
  state.fId = fId;
  if (fId === null) {
    (0, _utilities.log)('No f_id yet, or user has cookies turned off.');
  } else if (!(0, _utilities.isObjectId)(fId)) {
    (0, _utilities.log)('error', "Invalid f_id: ".concat(fId));
    state.fIdRejector(fId);
  }
  if (!ttdId) {
    (0, _utilities.log)('No ttd_id match yet.');
  } else {
    if (state.ttdIdTimeout) {
      clearTimeout(state.ttdIdTimeout);
    }
    state.ttdId = ttdId;
    state.ttdIdResolver(ttdId);
  }
  state.fIdResolver(fId);
  return !!fId && !!ttdId;
}
var _default = exports["default"] = match;