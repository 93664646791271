"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.assign.js");
require("core-js/modules/es.object.get-own-property-names.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _boomerang = require("./boomerang");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
if (!window.feathr_account_id) {
  window.feathr_account_id = window.feathr_event_id;
}
var feathrKeys = window.feathrKeys;
if (!feathrKeys) {
  feathrKeys = Object.getOwnPropertyNames(window).filter(function (key) {
    return key.indexOf('feathr') === 0;
  }).filter(function (key) {
    return !_boomerang.reservedKeys.includes(key);
  }).filter(function (key) {
    return typeof window[key] === 'function';
  });
}
if (feathrKeys.length === 0) {
  feathrKeys = ['feathr'];
}
var feathrs = feathrKeys.reduce(function (memo, key) {
  return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, key, window[key]));
}, {});
window.__feathrs = feathrs; // eslint-disable-line no-underscore-dangle

feathrKeys.forEach(function (key) {
  var _feathr = window[key];
  if (_feathr === undefined) {
    _feathr = function feathr() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return _boomerang.invoke.apply(_feathr, args);
    };
    _feathr.queue = [];
    window[key] = _feathr;
    feathrs[key] = _feathr;
  }
  _feathr.pixelKey = key;
  if (!_feathr.bootstrapped) {
    Object.assign(_feathr, {
      autopilot: _boomerang.autopilot,
      bootstrapped: true,
      isNewPixel: _boomerang.isNewPixel,
      readLog: _boomerang.readLog,
      invoke: _boomerang.invoke,
      process: _boomerang.process,
      renderPixelForUrl: _boomerang.renderPixelForUrl,
      renderPartnerDashboardButtons: _boomerang.renderPartnerDashboardButtons
    });
    if (_feathr.queue === undefined) {
      _feathr.queue = [];
    }
    _feathr.process(_feathr.queue);
    if (!_feathr.isNewPixel(_feathr)) {
      _feathr.autopilot();
    }
  }
});
var _default = exports["default"] = feathrs;